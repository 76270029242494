import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import './App.css';

const SeeResponses = () => {

    const [datefrom, setDateFrom] = useState(new Date());
    const [dateto, setDateTo] = useState(new Date());
    const [display, setDisplay] = useState(0)
    const [responses, setResponses] = useState([]);

    const getResponses = (from, to) => {
        fetch(`/api/getresponses?from=${from.toDateString()}&to=${to.toDateString()}`, {
            method:"get",
            headers: {'Accept':'application.json'},
        })
            .then(response => response.json())
            .then((json) => {
                setResponses(json)
            })
            .catch(error => {
                console.error(error)
                setResponses([])
            });
    }

    const countResponses = (score) => {
        return responses.filter((r) => r.score === score).length
    }

    return (
        <>
        {(display === 0) &&
            <div>
                <div style={{marginBottom:"20px"}}>
                    <label style={{display:"block"}}>From Date</label>
                    <input type="text" name="fromdate" id="topic" size="20" value={datefrom.toDateString()} onClick={() => {setDisplay(1)}}></input>
                </div>
                <div style={{marginBottom:"20px"}}>
                    <label style={{display:"block"}}>To Date</label>
                    <input type="text" name="fromdate" id="topic" size="20" value={dateto.toDateString()} onClick={() => {setDisplay(2)}}></input>
                </div>
                <Button onClick={() => getResponses(datefrom, dateto)}>Submit</Button>
            </div>
        }
        {(display === 1) &&
            <div>
                <Calendar onChange={setDateFrom} value={datefrom.toDateString()}/>
                <Button className="vertical-spacer" onClick={() => setDisplay(0)}>Close</Button>
            </div>
        }
        {(display === 2) &&
        <div>
            <Calendar onChange={setDateTo} value={dateto.toDateString()}/>
            <Button className="vertical-spacer" onClick={() => setDisplay(0)}>Close</Button>
        </div>
        }
        {(display === 0) && (responses.length > 0) &&
            <div className="response-results-box">
                <table className="table">
                    <tbody>
                    <tr>
                        <td scope="col">Total Responses</td>
                        <td scope="col">{responses.length}</td>
                    </tr>
                    <tr>
                        <td scope="col">Correct Responses</td>
                        <td scope="col">{countResponses(100)}</td>
                    </tr>
                    <tr>
                        <td scope="col">Wrong Responses</td>
                        <td scope="col">{countResponses(0)}</td>
                    </tr>
                    <tr>
                        <td scope="col">Partially Right Responses</td>
                        <td scope="col">{countResponses(50)}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        }
        </>
    )
}


export default SeeResponses

/*
<div className='app'>
    <h1 className='text-center'>Select From Date</h1>
<div className='calendar-container'>
    <Calendar onChange={setDateFrom} value={datefrom} />
    </div>
    <h1 className='text-center'>Select To Date</h1>
<div className='calendar-container'>
    <Calendar onChange={setDateTo} value={dateto} />
    </div>
    <p className='text-center'>
    <span className='bold'>From Date:</span>{' '}
{datefrom.toDateString()}
</p>
<p className='text-center'>
    <span className='bold'>To Date:</span>{' '}
    {dateto.toDateString()}
</p>
</div>
    */