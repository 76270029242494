import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';

const AddQuestion = () => {
    const [toggles, setToggles] = useState({A:false})
    const [popup, setPopup] = useState({open:false, message:""})
    const navigate = useNavigate()

    const showInfo = (message) => {
        setPopup({open:true, message})
    }

    const toggle = (e) => {
        //e.preventDefault()
        const t = {...toggles}
        if (t[e.target.name] !== null) {
            t[e.target.name] = !t[e.target.name]
            e.target.checked = t[e.target.name]
        }
        else
            t[e.target.name] = e.target.checked;
        setToggles(t)
    }

    const addChoice = (e) => {
        e.preventDefault()
        let x = Object.keys(toggles).reverse()[0]
        x = x.charCodeAt(0) + 1
        const choice = String.fromCharCode(x)
        const t = {...toggles}
        t[choice] = false
        setToggles(t)
    }

    const genChoices = () => {
        const display = []
        const choices = Object.keys(toggles).sort()
        for (let i=0; i<choices.length; i++) {
            const choice = choices[i];
            display.push(
                <div key={choice}>
                    <div>
                        <label style={{marginRight:"50px"}} for={choice}>Choice {choice}</label>
                        <input type="checkbox" name={choice} id={choice} onClick={toggle} className="me-2 flex-shrink-0 custom-radio"/>
                        <label className="form-check-label">This is a correct choice</label>
                    </div>
                    <textarea name={choice} id={choice} rows="2" cols="80"></textarea>
                    <div style={{height:"10px"}}></div>
                </div>
            )
        }
        return display
    }

    const submitQuestion = (e) => {
        e.preventDefault()
        const form = e.target.closest('form')
        const formData = new FormData(form)
        const formJson = Object.fromEntries(formData.entries())
        const q = {}
        q.question = formJson.question
        q.feedback = formJson.feedback
        q.topic = formJson.topic
        q.choices = []
        const choices = Object.keys(toggles).sort()
        for (let i=0; i<choices.length; i++) {
            const choice = {}
            choice.label = choices[i]
            choice.value = formJson[choices[i]]
            q.choices.push(choice)
        }
        q.rightchoice = []
        for (let i=0; i<choices.length; i++) {
            if (toggles[choices[i]] === true)
                q.rightchoice.push(choices[i])
        }
        q.setter = "Velayudhan Menon"
        q.used = false
        q.usedon = null
        q.insertedon = Date()
        fetch('/api/addquestion', {
            method:"post",
            headers: {'Content-Type':'application/json', 'Accept':'application.json'},
            body: JSON.stringify(q)
        })
            .then(response => response.json())
            .then((json) => {
                if (json.id > 0)
                    showInfo(`question successfully added. id is ${json.id}`)
                else
                    showInfo('insertion of question failed.')
            })
            .catch(error => console.error(error));
    }

    const closePopup = () => {
        setPopup({open:false, message:""})
    }

    return (
        <>
        {(popup.open)  &&
            <div>
                <h3>
                    {popup.message}
                </h3>
                <button type="text" onClick={closePopup}>Add Another Question</button>
                <button type="text" onClick={() => {navigate("/admin")}}>Return to Admin</button>
            </div>
        }

        {(!popup.open) &&
            <form>
                <div style={{marginBottom:"20px"}}>
                    <label style={{display:"block"}}>Topic</label>
                    <input type="text" name="topic" id="topic" size="80"></input>
                </div>
                <label>Question</label>
                <textarea name="question" id="question" rows="5" cols="80"></textarea>
                <div style={{height:"20px"}}></div>
                <h3>Answer Choices</h3>
                {genChoices()}
                <button onClick={addChoice}>Add Another Choice</button>
                <div style={{marginBottom:"20px"}}>
                    <label style={{marginTop:"20px"}}>Feedback</label>
                    <textarea name="feedback" id="feedback" rows="5" cols="80"></textarea>
                </div>
                <button type="submit" onClick={submitQuestion}>Submit Question</button>
            </form>
        }
        </>
    )
}


export default AddQuestion