import React, { useState, createContext, useContext } from 'react';
import './App.css';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';

const PageContext = createContext(null)
const QuestionContext = createContext(null)


const App = () => {
    const [ page, setPage ] = useState({page:1, score:-1});
    const [ question, setQuestion ] = useState(null);


    if (question === null) {
        fetch('/api/getquestion')
            .then(response => response.json())
            .then(json => setQuestion(json))
            .catch(error => console.error(error));
        return null
    };

    return (
        <PageContext.Provider value={{page, setPage}}>
            <QuestionContext.Provider value={{question}}>
                <Home/>
            </QuestionContext.Provider>
        </PageContext.Provider>
    )
}


const Home = () => {

    const { page, setPage } = useContext(PageContext);

    const selectPage = () => {
        if (page.page === 1)
            return <FirstPage/>
        return <SecondPage score={page.score}/>
    }

    return (
        selectPage()
    )
}

const Share = () => {
    return (
        <WhatsappShareButton
            url={'https://www.docquiz.in/'}
            title={'Try a medical question every weekday at: '}
            hashtag={'#medicalquiz'}>
            <WhatsappIcon size={40} round={true} />
        </WhatsappShareButton>
    )
}

const SecondPage = ({score}) => {
    const { question } = useContext(QuestionContext);

    return (
        <>
        <nav className="navbar navbar-expand-lg navbar-dark" aria-label="Main navigation">
            <Header/>
        </nav>
        <main className="container main-container">
            <div className="row">
            <div className="col">
                <div className="daily-quiz">
                    <ProgressBar q={question}/>
                    <Subject q={question}/>
                    <Feedback q={question} score={score}/>
                    <GoBack/>
                    <div style={{textAlign:"right"}}>
                        <div style={{display:"inline-block", marginRight:"2%"}}>Share with friends</div>
                        <Share/>
                    </div>

                </div>
            </div>
                </div>
        </main>
        </>
    )
}


const  FirstPage = () => {
    const { question } = useContext(QuestionContext);

    const holiday = isHoliday(question.serverDate)

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark" aria-label="Main navigation">
                <Header/>
            </nav>
            <main className="container main-container">
                <div className="row">
                    <div className="col">
                        <div className="daily-quiz">
                            <ProgressBar q={question}/>
                            {!holiday && <Subject q={question}/>}
                            {!holiday && <Question q={question}/>}
                            {holiday && <HolidayMessage/>}
                        </div>
                    </div>
                </div>
            </main>
        </>
    )

}

const HolidayMessage = () => {
    return (
        <p className="text-center">No quiz on Saturdays and Sundays</p>
    )
}

const ProgressBar = ({q}) => {

    const dateToday = () => {
        const today = new Date(q.serverDate);
        const month = today.toLocaleString('default', { month: 'long' });
        const day = today.getDate()
        const dow = today.toLocaleString('default', { weekday: 'long' });
        let daystr;
        switch(day) {
            case 1:
            case 21:
            case 31:
                daystr=`${day}st`;
                break;
            case 22:
            case 2: daystr=`${day}nd`;
                break;
            case 23:
            case 3: daystr=`${day}rd`;
                break;
            default:daystr=`${day}th`;
                break;
        }
        const year = today.getFullYear()
        return `${dow}, ${daystr} ${month} ${year}`
    }


    return (
        <>
            <h1 className="text-center">Clinical Data Interpretation</h1>
            <h3 className="text-center">A quiz a day, Monday to Friday</h3>
            <p className="text-center date">{dateToday()}</p>
            <p className="text-center"></p>
            <div className="progress mb-4">
                <div className="progress-bar" role="progressbar" style={{width: "100%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                </div>
            </div>
        </>
    )
}

const isHoliday = (serverDate) => {
    const today = new Date(serverDate);
    const dow = today.getDay();
    if ((dow === 0) || (dow > 5))
        return true
    return false
}

const Subject = ({q}) => {
    return (
        <div className="d-flex justify-content-between align-items-center mb-3">
            <h2>{q.topic || "General"}</h2>
        </div>
    )
}

const Header = () => {
    return (
        <div className="container">
            <a className="navbar-brand" href="#">DocQuiz</a>
            <button className="navbar-toggler p-0 border-0" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <a className="nav-link" href="#"></a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

const GoBack = () => {
    let { page, setPage } = useContext(PageContext);

    page = {...page}
    page.page = 1

    const handleClick = () => {
        setPage(page)
    }

    return (
        <div>
            <button type="button" onClick={handleClick}>See Question Again</button>
        </div>
    )
}

const GoToReview = () => {
    let { page, setPage } = useContext(PageContext);
    page = {...page}
    page.page = 2

    const handleClick = () => {
        setPage(page)
    }

    return (
        <div>
            <button type="button" onClick={handleClick}>See Feedback</button>
        </div>
    )
}

const Choices = ({q}) => {
    let { page, setPage } = useContext(PageContext)
    page = {...page}
    const [ clicked, setClicked ] = useState([])


    const multichoice = (q.rightchoice.length > 1)
    const handleSubmit = () => {

        const correctanswers = clicked.reduce((acc, cl) => {
            if (q.rightchoice.includes(cl))
                return acc+1
            else
                return acc
        }, 0)

        page.score = (correctanswers === q.rightchoice.length) && (clicked.length === correctanswers) ? 100 :
            ((correctanswers > 0) ?  50 : 0)

        fetch('/api/savescore', {
            method: "post",
            headers: {'Content-Type': 'application/json', 'Accept': 'application.json'},
            body: JSON.stringify({score:page.score, clicked})
        })
            .catch(error => console.error(error));

        page.page = 2
        page.clicked = clicked
        setPage(page)
    }
    const handleSelect = (e) => {
        const p = multichoice ? [...clicked] : []
        const id = e.target.id
        if (!p.includes(id))
            p.push(id)
        setClicked(p)
    }

    const showButton = () => {
        if (page.score === -1)
            return (
                <input type="button" value="submit" onClick={handleSubmit} className="btn btn-primary"
                       disabled={(clicked.length === 0)}></input>
            )
        else
            return GoToReview()
    }

    const isChecked = (key) => {
        if (!page.clicked)
            return false
        if (page.clicked.includes(key))
            return true
        return false
    }

    const setInput = (checked, name, id) => {
        if (page.score === -1)
            return (
                <input type="radio" name={name} onClick={handleSelect} id={id} className="me-2 flex-shrink-0 custom-radio"/>
            )
        return (
            <input type="radio" checked={checked ? "checked": ""} name={name} onClick={handleSelect} id={id} disabled={page.score >= 0} className="me-2 flex-shrink-0 custom-radio"/>
        )
    }


    const generateChoices = () => {
        const multichoice = (q.rightchoice.length > 1)
        const choices = []
        for (let i=0; i<q.choices.length; i++) {
            const key = q.choices[i].label
            const name = multichoice ? `${key}` : `key`;
            const checked = isChecked(key)
            const id = `${key}`

            choices.push(
                <li className="d-flex mb-4">
                    <span className="v-buffer">{key}</span>
                    {setInput(checked, name, id)}
                    <label className="form-check-label" for="question">
                        {q.choices[i].value}
                    </label>
                </li>
            )
        }
        return choices
    }
    return (
        <div className="pt-5 v-maxwidth">
            <ul>
                {generateChoices()}
            </ul>
            {showButton()}
        </div>
    )
}
/*<input  type="button" value="Submit" onClick={handleSubmit} className="btn btn-primary" ></input>
<li className="d-flex mb-4">
    <span className="v-buffer">A</span>
    <input type="radio" name="question" id="question4" className="me-2 flex-shrink-0 custom-radio"/>
    <label className="form-check-label" for="question1">
        When there are more than 5 exacerbations per year
    </label>
</li>
<li className="d-flex mb-4">
    <span className="v-buffer">B</span>
    <input type="radio" name="question" id="question3" className="me-2 flex-shrink-0 custom-radio"></input>
    <label className="form-check-label" for="question1">
    When exacerbations occur
without bacterial respiratory infections
</label>
</li>
<li className="d-flex mb-4">
    <span className="v-buffer">C</span>
    <input type="radio" name="question" id="question3" className="me-2 flex-shrink-0 custom-radio"></input>
    <label className="form-check-label" for="question1">
    When there is peripheral
blood eosinophilia
</label>
</li>
<li className="d-flex mb-4">
    <span className="v-buffer">D</span>
    <input type="radio" name="question" id="question4" className="me-2 flex-shrink-0 custom-radio"></input>
    <label className="form-check-label" for="question1">
    When there is peripheral
blood lymphocytosis
</label>
</li>*/

const Question = ({q}) => {

    return (
        <div style={{height:"100%"}}>
            <p>
                {q.question}
            </p>
            <Choices q={q}/>
        </div>
    )
}

const CorrectChoice = ({q, score}) => {

    const header = score === 100  ?  "Correct!" : (score === 50 ? "Partially right" : "Sorry, you are wrong")

    const getAnswers = () => {
        const answers = []
        for (let i=0; i<q.rightchoice.length; i++) {
            const label = q.rightchoice[i]
            const rightchoice = q.choices.find((ch) => {
                return ch.label === label
            })
            answers.push(
                <li className="d-flex mb-4 true">
                    <img src="/images/true.svg" alt="" className="me-2 flex-shrink-0"/>
                    {rightchoice.value}
                </li>
            )
        }
        return answers
    }
    return (
        <>
        <div className="row">
            <div className="col-md-7">
                <h3>{header}</h3>
                <ul>
                    {getAnswers()}
                </ul>
            </div>
        </div>
        </>
    )
}


const Feedback = ({q, score}) => {
    return (
        <>
        <div className="correct-answer">
            <CorrectChoice q={q} score={score}/>
            <div className="col-md-12" style={{marginTop:"20px"}}>
                <h3>Feedback</h3>
                <p>{q.feedback}</p>
            </div>
        </div>
        </>
    )
}

export default App



